import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import NotFound from '@/components/NotFoundPage.vue';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import CallBackView from '../views/CallBackView.vue';
import UnauthorizedView from '../views/UnauthorizedView.vue';
import AdminManagerView from '../views/AdminManagerView.vue';
import UserView from '../views/UserView.vue';
import InvitationView from '../views/InvitationView.vue';
import OldDashboardView from '../views/OldDashboardView.vue';
import Surveys from '../views/Survey/SurveyList.vue';
import CreateSurvey from '../views/Survey/CreateSurvey.vue';
import SurveyQuestions from '../views/Survey/SurveyQuestions.vue';

import { signIn, getUser } from '../services/AuthService';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        component: NotFound,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/callback',
        name: 'callback',
        component: CallBackView,
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: UnauthorizedView,
    },
    {
        path: '/adminmanager',
        name: 'adminmanager',
        component: AdminManagerView,
        meta: {
            requiresAuth: true,
            role: ['Administrator'],
        },
    },
    {
        path: '/adminmanager/:id',
        name: 'admin',
        component: UserView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/invitation',
        name: 'invitation',
        component: InvitationView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/surveys/',
        name: 'surveys',
        component: Surveys,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/surveys/:id',
        name: 'survey',
        component: SurveyQuestions,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/surveys/create',
        name: 'createSurvey',
        component: CreateSurvey,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/olddashboard',
        name: 'olddashboard',
        component: OldDashboardView,
        beforeEnter() {
            window.location.href = process.env.VUE_APP_DASHBOARD;
        },
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, _, next) => {
    const user = await getUser();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    const roles = to.meta.role as string[];
    const authorized = roles?.length > 0 ? roles.includes(user?.profile.role) : true;

    if (requiresAuth) {
        if (user && authorized) {
            return next();
        } else {
            await signIn(to.path);
            return next('unauthorized');
        }
    } else {
        next();
    }
});

export default router;
