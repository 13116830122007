
import { ref, defineComponent, onMounted, computed } from 'vue';
import Loading from 'quasar/src/plugins/Loading.js';;
import { signOut, getUser } from '@/services/AuthService';
import { useAuthStore } from '@/store/auth.store';

export default defineComponent({
    name: 'LayoutDefault',
    components: {},
    setup() {
        const authStore = useAuthStore();
        const { setUser } = authStore;

        const isAuthenticated = computed(() => authStore.isAuthenticated);
        const user = computed(() => authStore.user);

        Loading.show();

        onMounted(async () => {
            const result = await getUser();
            if (result) {
                setUser(result);
            }
        });

        const logout = () => signOut();

        return {
            user,
            isAuthenticated,
            leftDrawerOpen: ref(true),
            logout,
        };
    },

    created() {
        this.$watch('isAuthenticated', (signed: boolean) => {
            if (signed) {
                Loading.hide();
            }
        });
    },
});
