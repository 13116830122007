import { SurveyItem } from '@/models/SurveyItem';
import { Survey } from '@/models/Survey';
import axios from 'axios';
const url = process.env.VUE_APP_API_URL+ '/surveys/';

export default class SurveyService {
    static async createSurvey(name:string, startDate:string, endDate:string){
        return await axios.post<number>(url, {name, startDate, endDate});
    }

    static async getSurveys() {
        return (await axios.get<SurveyItem[]>(url)).data;
    }

    static async saveQuestions(surveyId:number, survey){
        const fullUrl = url+surveyId
        return await axios.post<void>(fullUrl, survey);
    }

    static async getSurvey(surveyId:number) {
        const fullUrl = url+surveyId
        return (await axios.get<Survey>(fullUrl)).data;
    }
}