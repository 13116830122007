import { defineStore } from 'pinia';
import { User } from 'oidc-client';
interface AuthStoreState {
    isAuthenticated: boolean;
    user: User | null;
    roles: Array<string>;
}

export const useAuthStore = defineStore('auth', {
    state: () =>
        ({
            isAuthenticated: false,
            user: null,
            roles: [],
        } as AuthStoreState),
    getters: {
        getAuthentication: (state) => {
            return state.isAuthenticated;
        },
    },
    actions: {
        setUser(user: User | null) {
            user
                ? this.$patch((state) => {
                      state.user = user;
                      state.isAuthenticated = user !== null ? true : false;
                      state.roles = user.profile.role;
                  })
                : null;
        },
    },
});
