
import { defineComponent, h } from 'vue';

export default defineComponent({
    name: 'ShowLockIcon',
    props: {
        isLocked: {
            type: Boolean,
        },
    },
    setup(props, { slots }) {
        return () =>
            props.isLocked
                ? h('div', {}, [
                      h(
                          'i',
                          {
                              style: { color: 'red' },
                              class: 'material-icons',
                          },
                          'lock'
                      ),
                      slots.default && slots.default(),
                  ])
                : '';
    },
});
