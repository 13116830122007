import { Ref } from 'vue';

export const loadingWrapper = async (func: Promise<any>, loading: Ref<boolean>) => {
    const result = await func;

    if (result !== undefined || result.status === 200) {
        loading.value = false;
    }

    return result;
};
