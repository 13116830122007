import axios from 'axios';
import Oidc from 'oidc-client';
import { User, UserManager, WebStorageStateStore } from 'oidc-client';

console.log(process.env.VUE_APP_AUTHENTICATION);

export const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: process.env.VUE_APP_AUTHENTICATION,
    redirect_uri: `${process.env.VUE_APP_ROOT_URL}/callback`,
    post_logout_redirect_uri: process.env.VUE_APP_ROOT_URL,
    client_id: process.env.VUE_APP_USER_MANAGER_CLIENT_ID,
    client_secret: process.env.VUE_APP_USER_MANAGER_CLIENT_SECRET,
    response_type: 'code',
    scope: 'openid profile email roles dataEventRecords offline_access',
    automaticSilentRenew: true,
    silent_redirect_uri: `${process.env.VUE_APP_ROOT_URL}/silent-renew.html`,
});

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

axios.interceptors.request.use(
    async (config) => {
        const user = await getUser();

        if (user?.expired) {
            signOut();
        }

        if (user) {
            const authToken = await getAccessToken();

            if (authToken && config.headers) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }
        }

        return config;
    },
    (err) => {
        return err;
    }
);

export async function getAccessToken(): Promise<string | undefined> {
    return (await userManager.getUser())?.access_token;
}

export function getUser(): Promise<User | null> {
    return userManager.getUser();
}

export async function signIn(returnPath: string) {
    returnPath ? userManager.signinRedirect({ state: returnPath }) : userManager.signinRedirect();
}

export function signOut() {
    userManager.signoutRedirect();
}
