
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoginView',
    components: {},
    setup() {
        return {};
    },
});
