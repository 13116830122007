
import { defineComponent, reactive, ref, toRef, watch } from 'vue';
import router from '@/router';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { MutlipleButtonLoading } from '@/models/Loading';
import { User } from '@/models/User';
import { Role } from '@/models/Role';
import { UserRequest } from '@/models/UserRequest';
import SpinerVew from '@/components/SpinerVew.vue';
import { loadingWrapper } from '../helper/LoadingWrap';

import UserService from '@/services/UserService';

export default defineComponent({
    name: 'UserView',
    components: { SpinerVew },
    setup() {
        const quasar = useQuasar();
        const loading = ref<boolean>(true);
        const loadingButton = reactive<MutlipleButtonLoading>({
            submit: false,
            reset: false,
            delete: false,
            lock: false,
        });
        let rolesOptions = ref<string[]>();
        const user: User = reactive({
            userId: '',
            email: '',
            userName: '',
            role: '',
            isLocked: false,
        });

        const isCurrentUser = ref<boolean>(false);
        const lockButtonColor = ref<string>('secondary');

        async function lockUser() {
            if (user.userId) {
                loadingButton.lock = true;

                const isLock = !user.isLocked as boolean;
                const updateUser: UserRequest = { email: user.email as string, IsLockUser: isLock };

                const result = await loadingWrapper(UserService.lockUser(updateUser), toRef(loadingButton, 'lock'));
                if (result.status === 200) {
                    quasar.notify({
                        color: 'primary',
                        position: 'top',
                        message: 'User updated',
                    });

                    user.isLocked = result.data;
                } else {
                    quasar.notify({
                        type: 'negative',
                        position: 'top',
                        message: 'User was not updated',
                    });
                }
            }
        }

        async function getRoles() {
            const roles = await UserService.getAllRoles();
            return roles && roles.map((role: Role) => role.name);
        }

        function abortFilterFn() {
            // 'delayed filter aborted'
        }

        async function filterFn(role: string, update: any, abort: any) {
            const roles = await getRoles();

            if (roles) {
                update(async () => {
                    rolesOptions.value =
                        role !== ''
                            ? roles.filter((r: string) => r.toLowerCase().indexOf(role.toLowerCase()) > -1)
                            : roles;
                });
            }
        }

        async function onSubmit() {
            loadingButton.submit = true;
            const result = await loadingWrapper(UserService.updateUser(user), toRef(loadingButton, 'submit'));

            if (result.status === 200) {
                quasar.notify({
                    color: 'primary',
                    position: 'top',
                    message: 'User updated',
                });

                router.back();
            } else {
                quasar.notify({
                    type: 'negative',
                    position: 'top',
                    message: 'User was not updated',
                });
            }
        }

        async function deleteUser() {
            loadingButton.delete = true;

            const result = await loadingWrapper(
                UserService.deleteUser(user.userId as string),
                toRef(loadingButton, 'delete')
            );

            if (result.status === 204) {
                quasar.notify({
                    color: 'primary',
                    position: 'top',
                    message: 'User was deleted',
                });

                router.back();
            } else {
                quasar.notify({
                    type: 'negative',
                    position: 'top',
                    message: 'User was not deleted',
                });
            }
        }

        async function resetPassword() {
            loadingButton.reset = true;
            if (user.email) {
                const result = await loadingWrapper(
                    UserService.resetPassword(user.email),
                    toRef(loadingButton, 'reset')
                );

                if (result.status === 200) {
                    quasar.notify({
                        color: 'primary',
                        position: 'top',
                        message: 'Password was send',
                    });
                } else {
                    quasar.notify({
                        type: 'negative',
                        position: 'top',
                        message: 'Password was not send',
                    });
                }
            }
        }

        watch([isCurrentUser, user], ([newIsCurrentUser, newUser]) => {
            if (newIsCurrentUser) {
                return (lockButtonColor.value = 'grey');
            }

            if (newUser.isLocked) {
                return (lockButtonColor.value = 'deep-orange');
            }

            lockButtonColor.value = 'secondary';
        });

        return {
            quasar,
            loading,
            loadingButton,
            rolesOptions,
            user,
            isCurrentUser,
            lockUser,
            filterFn,
            abortFilterFn,
            onSubmit,
            deleteUser,
            resetPassword,
            lockButtonColor,
        };
    },
    async created() {
        this.$watch(
            () => this.$route.params.id,
            async () => {
                if (this.$route.params.id) {
                    const user = await UserService.getUserById(this.$route.params.id as string);

                    if (user) {
                        Object.assign(this.user, user);
                        this.isCurrentUser = await UserService.isCurrentUser(user.email as string);
                    } else {
                        router.push({ path: '/notfound' });
                    }

                    this.loading = false;
                }
            },
            { deep: true, immediate: true }
        );
    },
});
