
import { ref, defineComponent, toRef, reactive } from 'vue';
import router from '../router/index';
import { Loading } from '@/models/Loading';
import { User } from '@/models/User';
import { loadingWrapper } from '@/helper/LoadingWrap';
import ShowLockIcon from '../helper/ShowLockIcon.vue';
import UserService from '@/services/UserService';

const columns = [
    {
        name: 'email',
        required: true,
        label: 'Email',
        align: 'left',
        field: 'email',
        sortable: true,
    },
    {
        name: 'role',
        align: 'center',
        label: 'Role',
        field: 'role',
        sortable: true,
    },
    {
        name: 'isLocked',
        align: 'center',
        label: 'Is locked',
        field: 'isLocked',
        sortable: true,
    },
];

export default defineComponent({
    name: 'UserListVue',
    emits: ['selectedUsers'],
    props: ['updatedRows'],
    components: { ShowLockIcon },
    setup() {
        const selected = ref<User[]>([]);
        const rows = ref<User[]>([]);
        const filter = ref<string>('');
        const loading = reactive<Loading>({ loading: true });

        async function getUsers() {
            const result = await loadingWrapper(UserService.getUsers(), toRef(loading, 'loading'));

            return result.map((user: User) => {
                return {
                    userId: user.userId,
                    email: user.email,
                    role: user.role,
                    isLocked: user.isLocked,
                };
            });
        }

        function updateRows() {
            rows.value = rows.value.filter((r: User) => !selected.value.includes(r));
        }

        function onRowClick(_: any, row: User) {
            router.push({ name: 'admin', params: { id: row.userId } });
        }

        return {
            pagination: {
                sortBy: 'email',
                descending: false,
                page: 0,
                rowsPerPage: 0,
            },
            loading,
            selected,
            filter,
            columns,
            rows,
            getUsers,
            updateRows,
            onRowClick,
        };
    },

    async created() {
        this.rows = await this.getUsers();
        this.$watch('selected', (selected: User[]) => this.$emit('selectedUsers', selected));
    },
});
