
import { defineComponent, reactive, ref, toRef } from 'vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import UserListVue from './UserListVue.vue';
import { Loading } from '@/models/Loading';
import { User } from '@/models/User';
import UserService from '@/services/UserService';
import { loadingWrapper } from '../helper/LoadingWrap';

export default defineComponent({
    name: 'AdminManagerView',
    components: { UserListVue },
    setup() {
        const quasar = useQuasar();
        const selectedRows = ref<User[]>([]);
        const childRef = ref<InstanceType<typeof UserListVue>>();
        const loadingButton = reactive<Loading>({ loading: false });

        async function deleteUser() {
            const ids = selectedRows.value.reduce((arr: string[], item: User) => {
                const { userId } = item;
                if (userId) {
                    arr.push(userId);
                }

                return arr;
            }, []);

            loadingButton.loading = true;
            const result = await loadingWrapper(UserService.deleteUsers(ids), toRef(loadingButton, 'loading'));

            if (result.status === 200) {
                quasar.notify({
                    color: 'primary',
                    position: 'top',
                    message: 'User was deleted',
                });
            } else {
                quasar.notify({
                    type: 'negative',
                    position: 'top',
                    message: 'User was not deleted',
                });
            }

            childRef.value?.updateRows();
            selectedRows.value.length = 0;
        }

        return {
            quasar,
            selectedRows,
            deleteUser,
            childRef,
            loadingButton,
        };
    },
    methods: {
        deleteRows(items: User[]) {
            this.selectedRows = items;
        },
    },
});
