import axios from 'axios';
import { Role } from '@/models/Role';
import { User } from '@/models/User';
import { UserRequest } from '@/models/UserRequest';

const returnUrl = process.env.VUE_APP_ROOT_URL;
const authenticationUrl = `${process.env.VUE_APP_AUTHENTICATION}/api`;
export default class UserService {
    private static _getRolesPromise: Promise<Role[]>;

    private static async getRoles() {
        return (await axios.get<Role[]>(`${authenticationUrl}/roles`)).data;
    }

    static async getAllRoles() {
        this._getRolesPromise = this.getRoles();
        return this._getRolesPromise;
    }

    static async getUsers() {
        return (await axios.get<User[]>(`${authenticationUrl}/all`)).data;
    }

    static async getUserById(id: string) {
        return (await axios.get<User>(`${authenticationUrl}/${id}`)).data;
    }

    static async registerUser(user: User) {
        return await axios.post<void>(`${authenticationUrl}/register-user`, user);
    }

    static async updateUser(user: User) {
        return await axios.put<void>(`${authenticationUrl}/update`, user);
    }

    static async deleteUsers(ids: Array<string>) {
        return await axios.post<void>(`${authenticationUrl}/list/delete`, ids);
    }

    static async resetPassword(email: string) {
        return await axios.post<void>(`${authenticationUrl}/reset-password`, { email, returnUrl });
    }

    static async deleteUser(id: string) {
        return await axios.put<any>(`${authenticationUrl}/delete-user/${id}`);
    }

    static async lockUser(userRequest: UserRequest) {
        return await axios.put<any>(`${authenticationUrl}/lock-user`, userRequest);
    }

    static async isCurrentUser(email: string) {
        return (await axios.get<any>(`${authenticationUrl}/current/${email}`)).data;
    }
}
