import script from "./SurveyQuestions.vue?vue&type=script&setup=true&lang=ts"
export * from "./SurveyQuestions.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QDate,QBtn,QSeparator,QUploader,QCard,QCardSection,QSelect,QCardActions});qInstall(script, 'directives', {ClosePopup});
