
import { userManager } from '../services/AuthService';
import router from '../router/index';
import { useAuthStore } from '@/store/auth.store';

export default {
    async created() {
        try {
            const authStore = useAuthStore();
            const { setUser } = authStore;

            let result = await userManager.signinRedirectCallback();
            let returnToUrl = '/';
            if (result.state !== undefined) {
                returnToUrl = result.state;
            }

            setUser(result);
            router.push({ path: returnToUrl });
        } catch (e) {
            router.push({ path: 'unauthorized' });
        }
    },
};
