
import { defineComponent, reactive, ref, toRef } from 'vue';
import router from '@/router';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { Loading } from '@/models/Loading';
import { User } from '@/models/User';
import { Role } from '@/models/Role';
import UserService from '@/services/UserService';
import { loadingWrapper } from '@/helper/LoadingWrap';
import { validateEmail } from '../helper/ValidateEmail';

export default defineComponent({
    name: 'InvitationView',
    components: {},
    setup() {
        const quasar = useQuasar();
        const sendButton = reactive<Loading>({ loading: false });
        const rolesOptions = ref<string[]>();
        const selectedRole = ref<string>('');
        const email = ref<string>('');

        const formComponent = ref();

        let form = ref({
            email: '',
            selectedRole: '',
        });

        async function onSubmit() {
            const valid = await formComponent.value.validate();

            if (valid) {
                const user: User = {
                    email: form.value.email,
                    role: form.value.selectedRole,
                };

                sendButton.loading = true;
                const result = await loadingWrapper(UserService.registerUser(user), toRef(sendButton, 'loading'));

                if (result.status === 200) {
                    quasar.notify({
                        color: 'primary',
                        position: 'top',
                        message: 'User was register',
                    });

                    router.back();
                } else {
                    quasar.notify({
                        type: 'negative',
                        position: 'top',
                        message: 'User was not register',
                    });
                }
            }
        }

        async function onReset() {
            form.value = {
                email: '',
                selectedRole: '',
            };

            formComponent.value.resetValidation();
        }

        return {
            formComponent,
            form,
            onSubmit,
            onReset,

            quasar,
            sendButton,
            selectedRole,
            rolesOptions,
            email,
            validateEmail,
        };
    },

    methods: {
        async filterFn(value: string, update: any, abort: any) {
            const roles = await this.getRoles();

            if (roles) {
                update(async () => {
                    this.rolesOptions =
                        value !== ''
                            ? roles.filter((r: string) => r.toLowerCase().indexOf(value.toLowerCase()) > -1)
                            : roles;
                });
            }
        },

        abortFilterFn() {
            //Todo: 'delayed filter aborted'
        },

        async getRoles() {
            const roles = await UserService.getAllRoles();
            return roles && roles.map((role: Role) => role.name);
        },
    },
});
